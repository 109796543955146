/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';
import { headData } from '../mock/data';
import '../style/main.scss';
import NavbarComponent from '../components/Navbar/Navbar';

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TeamPage</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="Team Page" />
      </Helmet>
      <NavbarComponent />
      <section id="hero" className="jumbotron">
      <iframe src="https://teststyle.retool.com/embedded/public/becd9ca4-06d8-4a2d-b5fe-71cd2ed02c6c" width="100%" height="100%"> </iframe>
        
      </section>
      <Container>
          
          
          
          <Fade bottom duration={1000} delay={1000} distance="30px">
            <p className="hero-cta justify-content-center">
              <Link className="cta-btn cta-btn--hero" to="/">
                Go back
              </Link>
            </p>
          </Fade>
        </Container>
    </>
  );
};